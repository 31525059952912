import React from "react";

export default class Education extends React.Component {
    render() {
        return (<div id='outer'>
            <div id='middle'>

                <div id='inner'>
                </div>
            </div>
        </div>
        );
    }
}
